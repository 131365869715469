// Generated by Framer (16060ad)

import { addFonts, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {c9i9i2lGO: {hover: true}};

const cycleOrder = ["c9i9i2lGO"];

const variantClassNames = {c9i9i2lGO: "framer-v-tmzqob"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "c9i9i2lGO", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "c9i9i2lGO", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-4U5FS", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-tmzqob", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"c9i9i2lGO"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"c9i9i2lGO-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-j6tm31"} data-framer-name={"home_logo_ckw"} fill={"black"} intrinsicHeight={70} intrinsicWidth={160} layoutDependency={layoutDependency} layoutId={"MIoaNDe4o"} style={{opacity: 0.5}} svg={"<svg data-name=\"Component 824 – 1\" xmlns=\"http://www.w3.org/2000/svg\" width=\"160\" height=\"70\"><g data-name=\"Group 10069\"><path data-name=\"Path 3057\" d=\"M57.119 20.172h6.627v12.8l12.219-12.8h7.771L71.681 32.787l12.85 17.38h-7.858l-9.436-12.769-3.491 3.687v9.082h-6.627v-30Zm-15.49 24.425a9.176 9.176 0 0 1-9.047-9.427v-.34a9.176 9.176 0 0 1 9.047-9.427c3.441 0 5.549 1.37 7.914 3.51l3.6-4.882a14.729 14.729 0 0 0-11.495-4.714 15.371 15.371 0 0 0-15.683 15.428v.511a15.37 15.37 0 0 0 15.683 15.426 14.731 14.731 0 0 0 11.491-4.714l-3.6-4.882c-2.365 2.141-4.474 3.51-7.914 3.51Z\" fill=\"#666\" fill-rule=\"evenodd\"/><path data-name=\"Path 3058\" d=\"m121.453 20.153-6.778 20.919-6.61-20.901h-4.586l-6.655 20.875-6.765-20.88-6.66.005 9.775 29.986h6.691l5.869-18.7 5.973 18.7h6.676l9.765-30h-6.7Z\" fill=\"#666\" fill-rule=\"evenodd\"/><g data-name=\"Group 10068\"><path data-name=\"Path 3059\" d=\"M130.097 50.682a3.921 3.921 0 1 0-3.936-3.92 3.929 3.929 0 0 0 3.936 3.92Z\" fill=\"#666\" fill-rule=\"evenodd\"/></g></g></svg>"} transition={transition} variants={{"c9i9i2lGO-hover": {opacity: 1}}} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-4U5FS [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4U5FS .framer-tvoq7z { display: block; }", ".framer-4U5FS .framer-tmzqob { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 160px; }", ".framer-4U5FS .framer-j6tm31 { aspect-ratio: 2.2857142857142856 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 70px); position: relative; width: 100%; }", ".framer-4U5FS .framer-v-tmzqob .framer-tmzqob { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-4U5FS .framer-tmzqob { gap: 0px; } .framer-4U5FS .framer-tmzqob > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-4U5FS .framer-tmzqob > :first-child { margin-left: 0px; } .framer-4U5FS .framer-tmzqob > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 70
 * @framerIntrinsicWidth 160
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"WUCKwibgd":{"layout":["fixed","auto"]}}}
 */
const FramermE6QEwWdy: React.ComponentType<Props> = withCSS(Component, css, "framer-4U5FS") as typeof Component;
export default FramermE6QEwWdy;

FramermE6QEwWdy.displayName = "logo_ckw";

FramermE6QEwWdy.defaultProps = {height: 70, width: 160};

addFonts(FramermE6QEwWdy, [])